.laurel .laurel-img {
    width:185px;
}
.laurel {
    /* margin-left:21px; */
    display: table !important;
    position:relative;
    filter:invert(1);
     /* margin-top:21px; */
    /* margin-bottom:0px; */
}
.laurel-text {
    position:absolute;
    width:100%;
    text-align: center;
    top:12px;
    color:#000;
    font-size:13px;
    font-weight:bold;
    text-shadow:none;
}
.laurel-stars {
    position:absolute;
    width:100%;
    text-align: center;
    top:36px;
    display: flex; /* Add this line if necessary */
    justify-content: center; /* Center the stars horizontally */
    align-items: center; /* Align the stars vertically */
}
.laurel-stars-img {
    width:13px;
    margin-left:2px;
    margin-right:2px;
}

@media (max-width:1023px) {
    .laurel {
        margin:0 auto !important;
    }
}



.hero_footer_logos {
    white-space: nowrap;
    overflow-x:scroll;
    overflow-y:hidden;
    text-align: center;
    position:absolute;
    bottom:0;
    left:0;
    z-index:10;
    width:100%;
    padding:14px;
}
.hero_footer_logos {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hero_footer_logos::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.hero_footer_logos img {
    pointer-events: none;
    margin-right: 14px;
    height:25px;
    filter:invert(1) saturate(0) brightness(2);
    mix-blend-mode: lighten;
    vertical-align: middle;
}